import styled from "styled-components";

import { Col, Row, SectionContainer } from "../base_components/layout";
import { sizes } from "../base_components/screenSizes";
import { colorMap } from "../base_components/colors";
import { Typography } from "../base_components/typography";
import {
  ImageBackgroundWrapper,
  ImageWrapper,
} from "../base_components/images";

export const ProjectContainer = styled(SectionContainer)`
  background: ${colorMap.white};
  overflow: hidden;
  @media (max-width: ${sizes.small}px) {
    text-align: left;
  }
`;

export const ProjectTitleStyle = styled(Typography.H1)`
  color: ${colorMap.fontLightBlue};
  @media (max-width: ${sizes.large}px) {
    padding-left: 20px;
  }

`;

export const ProjectNameStyle = styled(Typography.H2)`
  color: ${colorMap.brandPurple};
`;

export const ProjectContentStyle = styled(Typography.body)`
  color: ${colorMap.fontBlue};
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const ProjectCol = styled(Col)`
  @media (max-width: ${sizes.large}px) {
    background: rgba(255, 255, 255, 0.6);
    margin-top: 20px;
    padding: 20px;
    border-radius: 5px;
  }

  @media (max-width: ${sizes.small}px) {
    margin-top: 0px;
    margin-bottom: 20px;
  }
`;

export const ProjectImgCol = styled(Col)`
  display: flex;
  justify-content: center;
`;

export const ProjectRow = styled(Row)`
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: ${props => (props.isRight ? "row-reverse" : "initial")};
  margin-top: 0px;
  padding: 60px 0px;

  @media (max-width: ${sizes.medium}px) {
    flex-wrap: wrap-reverse;
    margin-top: 0px;
  }

  @media (max-width: ${sizes.small}px) {
    padding: 10px 10px;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TagNameStyle = styled(Typography.SH1)`
  color: ${colorMap.brandPurple};
`;

export const TagStyle = styled.div`
  color: ${colorMap.brandPurple};
  border-radius: 5px;
  border: 3px solid ${colorMap.brandPurple};
  width: fit-content;
  padding: 8px;
  margin-right: 12px;
  margin-top: 12px;
`;

export const ButtonHolder = styled.div`
  width: fit-content;
  @media (max-width: ${sizes.medium}px) {
    display: flex;
    justify-content: center;
    width: auto;
  }
`;
export const BlobHolder = styled.div`
  right: ${props => (props.isRight ? "initial" : "-40%")};
  left: ${props => (props.isRight ? "-40%" : "initial")};
  position: absolute;
  height: 700px;
  width: 800px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  transition: all 700ms;

  @media (min-width: ${sizes.xl}px) {
    width: 800px;
    right: ${props => (props.isRight ? "initial" : "-30%")};
    left: ${props => (props.isRight ? "-30%" : "initial")};
  }

  @media (max-width: ${sizes.medium}px) {
    width: 600px;
  }

  @media (max-width: ${sizes.small}px) {
    width: 500px;
  }
`;

export const ProjectImgHolder = styled(ImageBackgroundWrapper)`
  width: 100%;
  height: 900px;
  max-height: 600px;

  @media (max-width: ${sizes.medium}px) {
    max-height: 400px;
  }
  @media (max-width: ${sizes.small}px) {
    max-height: 350px;
  }
`;

export const ProjectImg = styled(ImageWrapper)`
  max-height: 550px;
  object-fit: contain;

  @media (max-width: ${sizes.medium}px) {
    max-height: 350px;
  }
  @media (max-width: ${sizes.small}px) {
    max-height: 300px;
  }
`;
