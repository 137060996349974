import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { RichText } from "prismic-reactjs-custom";

import {
  ProjectContainer,
  ProjectTitleStyle,
  ProjectCol,
  ProjectImgCol,
  ProjectRow,
  ProjectNameStyle,
  ProjectContentStyle,
  TagStyle,
  TagContainer,
  TagNameStyle,
  BlobHolder,
  ProjectImgHolder,
  ProjectImg,
  ButtonHolder,
} from "./projects_styles";

import { InternalContainer } from "../base_components/layout";

import { PrimaryButton } from "../base_components/buttons";

import { Blob } from "./blob";

const Tag = props => {
  const { tagName } = props;
  return (
    <TagStyle>
      <TagNameStyle>{tagName}</TagNameStyle>
    </TagStyle>
  );
};

const projectNameToUrl = {
  Terrene: "/projects/terrene",
  ThoughtExchange: "/projects/thought_exchange",
};

const ProjectItem = props => {
  const { project, isRight } = props;
  const name = project.project_name[0].text;
  const date = project.project_date[0].text;
  const title = name + " • " + date;
  const tags = project.project_tags[0].text.split(",");
  const content = project.project_description;
  const img = project.project_img;
  const projectLink = project.project_link;
  const projectUrl = projectLink ? projectLink.url : "";
  const readMoreUrl = projectNameToUrl[name] || null;

  return (
    <ProjectRow isRight={isRight}>
      <ProjectCol size={1} breakSize="medium">
        <ProjectNameStyle>{title}</ProjectNameStyle>
        <TagContainer>
          {tags.map((item, index) => {
            return <Tag id={index} tagName={item} />;
          })}
        </TagContainer>
        <RichText richText={content} paragraph={ProjectContentStyle} />

        {readMoreUrl && (
          <ButtonHolder>
            <PrimaryButton
              shadowColor={"brandPink"}
              buttonColor={"fontLightBlue"}
              textColor={"white"}
              href={readMoreUrl}
            >
              learn more
            </PrimaryButton>
          </ButtonHolder>
        )}

        {projectUrl && (
          <ButtonHolder>
            <PrimaryButton
              shadowColor={"brandPink"}
              buttonColor={"fontLightBlue"}
              textColor={"white"}
              href={projectUrl}
              target="_blank"
            >
              view the site
            </PrimaryButton>
          </ButtonHolder>
        )}
      </ProjectCol>
      <ProjectImgCol size={1} breakSize="medium">
        <ProjectImgHolder>
          <ProjectImg src={img.url} alt={img.alt} />
        </ProjectImgHolder>
        <BlobHolder isRight={isRight}>
          <Blob />
        </BlobHolder>
      </ProjectImgCol>
    </ProjectRow>
  );
};

const Projects = props => {
  const { data } = props;
  const title = data.prismic.allHomepages.edges[0].node.projects_title[0].text;
  const projects = data.prismic.allHomepages.edges[0].node.projects;

  return (
    <ProjectContainer id="projects">
      <InternalContainer>
        <ProjectTitleStyle>{title}</ProjectTitleStyle>

        {projects.map((item, index) => {
          const isRight = index % 2 !== 0;
          return <ProjectItem isRight={isRight} id={index} project={item} />;
        })}
      </InternalContainer>
    </ProjectContainer>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query Projects {
        prismic {
          allHomepages {
            edges {
              node {
                projects_title
                projects {
                  project_date
                  project_description
                  project_img
                  project_name
                  project_tags
                  project_link {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Projects data={data} {...props} />}
  />
);
