import React from "react";

import { ParallaxProvider } from "react-scroll-parallax";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/index/hero";
import About from "../components/index/about";
import Projects from "../components/index/projects";

const IndexPage = () => (
  <ParallaxProvider>
    <Layout showHeader={true}>
      <SEO title="Home" />
      <Hero />
      <About />
      <Projects />
    </Layout>
  </ParallaxProvider>
);

export default IndexPage;
