import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { RichText } from "prismic-reactjs-custom";
import { Parallax } from "react-scroll-parallax";

import { colorMap } from "../base_components/colors";

import pdf from "../../resume/anika_jagow.pdf";

import {
  AboutContainer,
  AboutInternalContainer,
  AboutTitleStyle,
  AboutSubTitleStyle,
  AboutContentStyle,
  AboutRow,
  AboutCol,
  AboutImgCol,
  AboutIconRow,
  CircleIcon,
  CircleHolder,
  ButtonHolder,
  BottomFernContainer,
  PencilHolder,
  BobaLeft,
  BobaMiddle,
  BobaRight,
  AboutRowSection,
  TreeHolder,
  TentHolder,
  LeftHeartHolder,
  MiddleHeartHolder,
  RightHeartHolder,
  BowlHolder,
  ContactLinkHolder,
  ContactLink
} from "./about_styles";

import BottomFernOutline from "../../images/bfernoutline.svg";
import BottomFernFill from "../../images/bfernfill.svg";

import IPad from "../../images/interests/ipad.svg";
import Pencil from "../../images/interests/pencil.svg";

import Boba from "../../images/interests/boba.svg";
import Pearls from "../../images/interests/pearls.svg";

import Trees from "../../images/interests/trees.svg";
import Tent from "../../images/interests/tent.svg";

import Bowl from "../../images/interests/bowl.svg";
import Heart from "../../images/interests/heart.svg";

import Linkedin from "../../images/linkedin.svg";
import Github from "../../images/github.svg";
import Email from "../../images/mail.svg";

import { PrimaryButton } from "../base_components/buttons";
import { TextContainer } from "../base_components/layout";

const AboutSubSection = props => {
  console.log(props);
  const aboutTitle = props.section.about_me_section_title[0].text;
  const aboutContent = props.section.about_me_section_content;
  return (
    <AboutCol size={1} breakSize="large">
      <AboutSubTitleStyle>{aboutTitle}</AboutSubTitleStyle>
      <RichText richText={aboutContent} paragraph={AboutContentStyle} />
    </AboutCol>
  );
};

const About = props => {
  const { data } = props;
  const aboutTitle = data.prismic.allHomepages.edges[0].node.about_me[0].text;
  const aboutContent = data.prismic.allHomepages.edges[0].node.about_me_content;
  const aboutMeSection =
    data.prismic.allHomepages.edges[0].node.about_me_section;

  return (
    <AboutContainer id="about">
      <AboutInternalContainer>
        <AboutRow>
          <AboutImgCol size={1} breakSize="large">
            <AboutIconRow>
              <CircleHolder>
                <CircleIcon backgroundColor={"brandPink"} isHidden>
                  <TreeHolder>
                    <Parallax x={[30, -30]}>
                      <Trees />
                    </Parallax>
                  </TreeHolder>
                  <TentHolder>
                    <Tent />
                  </TentHolder>
                </CircleIcon>
              </CircleHolder>
              <CircleHolder>
                <CircleIcon backgroundColor={"brandPink"}>
                  <BobaLeft>
                    <Parallax y={[-30, 30]}>
                      <Pearls />
                    </Parallax>
                  </BobaLeft>
                  <BobaMiddle>
                    <Parallax y={[40, -40]}>
                      <Pearls />
                    </Parallax>
                  </BobaMiddle>
                  <BobaRight>
                    <Parallax y={[-30, 30]}>
                      <Pearls />
                    </Parallax>
                  </BobaRight>
                  <Boba />
                </CircleIcon>
              </CircleHolder>
              <CircleHolder>
                <CircleIcon backgroundColor={"brandPink"}>
                  <PencilHolder>
                    <Parallax x={[-20, 20]}>
                      <Pencil />
                    </Parallax>
                  </PencilHolder>

                  <IPad />
                </CircleIcon>
              </CircleHolder>
              <CircleHolder>
                <CircleIcon backgroundColor={"brandPink"}>
                  <BowlHolder>
                    <Bowl />
                  </BowlHolder>

                  <LeftHeartHolder>
                    <Parallax y={[-100, 100]}>
                      <Heart />
                    </Parallax>
                  </LeftHeartHolder>
                  <MiddleHeartHolder>
                    <Parallax y={[200, -200]}>
                      <Heart />
                    </Parallax>
                  </MiddleHeartHolder>
                  <RightHeartHolder>
                    <Parallax y={[-100, 100]}>
                      <Heart />
                    </Parallax>
                  </RightHeartHolder>
                </CircleIcon>
              </CircleHolder>
            </AboutIconRow>
          </AboutImgCol>

          <AboutCol size={1} breakSize="large">
            <TextContainer isColumn={true} style={{ padingTop: 0 }}>
              <AboutTitleStyle>{aboutTitle}</AboutTitleStyle>
              <RichText richText={aboutContent} paragraph={AboutContentStyle} />
              <ContactLinkHolder>
                <ContactLink href="mailto:jagow.anika@gmail.com" target="_blank"><Email /></ContactLink>
                <ContactLink href="https://github.com/ajagow" target="_blank"><Github /></ContactLink>
                <ContactLink href="https://www.linkedin.com/in/anika-jagow-48647514b/" target="_blank"><Linkedin /></ContactLink>
              </ContactLinkHolder>
              <ButtonHolder>
                <PrimaryButton
                  href={pdf}
                  target="_blank"
                  shadowColor={"brandPink"}
                  buttonColor={"brandYellow"}
                  textColor={"fontLightBlue"}
                >
                  see resume
                </PrimaryButton>
              </ButtonHolder>
            </TextContainer>
          </AboutCol>
        </AboutRow>

        <AboutRowSection style={{ alignItems: `self-end` }}>
          <TextContainer>
            {aboutMeSection.map((item, index) => {
              return <AboutSubSection id={index} section={item} />;
            })}
          </TextContainer>
        </AboutRowSection>
      </AboutInternalContainer>
    </AboutContainer>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query AboutQuery {
        prismic {
          allHomepages {
            edges {
              node {
                about_me
                about_me_content
                about_me_section {
                  about_me_section_content
                  about_me_section_title
                }
              }
            }
          }
        }
      }
    `}
    render={data => <About data={data} {...props} />}
  />
);
