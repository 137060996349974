import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Parallax } from "react-scroll-parallax";

import {
  DetailContainer,
  HeroImg,
  HeroInternal,
  HeroCol,
  HeroImgCol,
  HeroRow,
  HeroContainer,
  DetailStyle,
  DetailNameStyle,
  LeftBlob,
  RightBlob,
  TopFernContainer,
  BottomFernContainer,
} from "./hero_styles";

import YellowBlob from "../../images/yellowblob.svg";
import PinkBlob from "../../images/pinkblob.svg";

import BottomFernOutline from "../../images/bfernoutline.svg";
import BottomFernFill from "../../images/bfernfill.svg";

import { ImageStyle } from "../base_components/images";

const Hero = props => {
  const { data } = props;
  const welcome = data.prismic.allHomepages.edges[0].node.welcome1[0].text;
  const name = data.prismic.allHomepages.edges[0].node.welcome2[0].text;
  const title = data.prismic.allHomepages.edges[0].node.welcome3[0].text;

  const img = data.prismic.allHomepages.edges[0].node.profile_img;

  return (
    <HeroContainer>
      <HeroInternal>
        <HeroRow>
          <LeftBlob>
            <Parallax x={[-15, 15]}>
              <YellowBlob />
            </Parallax>
          </LeftBlob>

          <RightBlob>
            <Parallax x={[15, -15]}>
              <PinkBlob />
            </Parallax>
          </RightBlob>

          <BottomFernContainer>
            <Parallax className="custom-class" y={[-10, 10]}>
              <BottomFernFill />
            </Parallax>
          </BottomFernContainer>

          <BottomFernContainer>
            <BottomFernOutline />
          </BottomFernContainer>

          <TopFernContainer>
            <Parallax className="custom-class" y={[-10, 10]}>
              <BottomFernFill />
            </Parallax>
          </TopFernContainer>

          <TopFernContainer>
            <BottomFernOutline />
          </TopFernContainer>

          <HeroCol size={1} breakSize="medium">
            <DetailContainer>
              <DetailStyle>{welcome}</DetailStyle>
              <DetailNameStyle>{name}</DetailNameStyle>
              <DetailStyle>{title}</DetailStyle>
            </DetailContainer>
          </HeroCol>

          <HeroImgCol size={1} breakSize="medium">
            <ImageStyle imgSrc={img.url} />
          </HeroImgCol>
        </HeroRow>
      </HeroInternal>
    </HeroContainer>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query HeroQuery {
        prismic {
          allHomepages {
            edges {
              node {
                welcome1
                welcome2
                welcome3
                profile_img
              }
            }
          }
        }
      }
    `}
    render={data => <Hero data={data} {...props} />}
  />
);
