import styled from "styled-components";

import {
  Col,
  Row,
  SectionContainer,
  InternalContainer,
} from "../base_components/layout";
import { sizes } from "../base_components/screenSizes";
import { colorMap } from "../base_components/colors";
import { Typography } from "../base_components/typography";

export const AboutContainer = styled(SectionContainer)`
  ${"" /* background: ${colorMap.brandBlue}; */}
`;

export const AboutInternalContainer = styled(InternalContainer)`
  background: ${colorMap.brandBlue};
`;

export const AboutTitleStyle = styled(Typography.H1)`
  color: ${colorMap.brandPurple};
`;

export const AboutSubTitleStyle = styled(Typography.H2)`
  color: ${colorMap.fontLightBlue};
`;

export const AboutContentStyle = styled(Typography.body)`
  color: ${colorMap.fontBlue};

  strong {
    font-family: "Noto Sans", sans-serif;
  }
`;

export const AboutCol = styled(Col)`
  padding: 20px;
  @media (max-width: ${sizes.small}px) {
    text-align: left;
  }
`;

export const AboutImgCol = styled(Col)`
  display: flex;
  justify-content: center;
`;

export const AboutRowSection = styled(Row)`
  align-items: center;
  @media (max-width: ${sizes.medium}px) {
    flex-wrap: wrap;
    margin-top: 40px;
  }

  @media (max-width: ${sizes.small}px) {
    margin-top: 0px;
  }
`;

export const AboutRow = styled(Row)`
  align-items: center;
  @media (max-width: ${sizes.medium}px) {
    flex-wrap: wrap-reverse;
    margin-top: 40px;
  }
`;

export const AboutIconRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 20px;

  @media (max-width: ${sizes.medium}px) {
    max-width: 500px;
    justify-content: space-evenly;
  }

  @media (max-width: ${sizes.small}px) {
    max-width: 330px;
    justify-content: space-evenly;
  }
`;

export const CircleHolder = styled.div`
  display: flex;
  flex-basis: 50%;
  justify-content: center;

  @media (max-width: ${sizes.large}px) {
    flex-basis: 25%;
  }

  @media (max-width: ${sizes.medium}px) {
    flex-basis: 25%;
  }
`;

export const CircleIcon = styled.div`
  height: 184px;
  width: 184px;
  background-color: ${props => colorMap[props.backgroundColor]};
  border-radius: 50%;
  display: inline-block;
  margin-top: 40px;
  position: relative;
  padding: 8px;
  overflow: ${props => (props.isHidden ? "hidden" : "initial")};
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);

  @media (max-width: ${sizes.large}px) {
    height: 125px;
    width: 125px;
  }

  @media (max-width: ${sizes.medium}px) {
    height: 175px;
    width: 175px;
  }

  @media (max-width: ${sizes.small}px) {
    height: 100px;
    width: 100px;
  }
`;

export const ButtonHolder = styled.div`
  margin-top: 40px;
  width: fit-content;

  @media (max-width: ${sizes.medium}px) {
    display: flex;
    justify-content: center;
    width: auto;
  }
`;

export const BottomFernContainer = styled.div`
  position: absolute;
  top: -40%;
  left: -10%;
  z-index: 99999999;
  width: 800px;
  transition: all 700ms;
  fill: #a4c7df;

  @media (max-width: 980px) {
    top: -65%;
  }

  @media (max-width: ${sizes.medium}px) {
    width: 500px;
    top: -25%;
    left: -10%;
  }

  @media (max-width: ${sizes.small}px) {
    width: 350px;
    top: -15%;
    left: -10%;
  }
`;

export const PencilHolder = styled.div`
  height: 100px;
  position: absolute;
  top: 0;
  transform: rotate(7deg);
`;

export const BobaLeft = styled.div`
  width: 15px;
  position: absolute;
  bottom: 15px;
  left: 35%;
`;

export const BobaMiddle = styled.div`
  width: 15px;
  position: absolute;
  bottom: 20px;
  left: 45%;
`;

export const BobaRight = styled.div`
  width: 15px;
  position: absolute;
  bottom: 15px;
  left: 55%;
`;

export const TreeHolder = styled.div`
  position: absolute;
  bottom: 20px;
  position: absolute;
  bottom: -5px;
  width: 150%;
  left: 50%;
  transform: translateX(-50%);
`;

export const TentHolder = styled.div`
  position: absolute;
  bottom: 0px;
`;

export const BowlHolder = styled.div`
  position: absolute;
  left: 20px;
  bottom: 5px;
`;

export const LeftHeartHolder = styled.div`
  position: absolute;
  top: 20%;
  left: 30%;
  width: 20px;
`;

export const MiddleHeartHolder = styled.div`
  position: absolute;
  top: 10%;
  left: 42%;
  width: 20px;
`;

export const RightHeartHolder = styled.div`
  position: absolute;
  top: 20%;
  left: 55%;
  width: 20px;
`;

export const ContactLinkHolder = styled.div`
  height: 50px;
  display: flex;
  margin-top: 20px;

  @media (max-width: ${sizes.medium}px) {
    display: flex;
    justify-content: center;
  }
`;

export const ContactLink = styled.a`
  height: 50px;
  width: 50px;
  margin-right: 20px;
  fill: ${colorMap.brandPurple};

  :hover {
    fill: ${colorMap.brandPink};
  }
`;