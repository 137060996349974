import styled from "styled-components";

import { Col, Row } from "../base_components/layout";
import { sizes } from "../base_components/screenSizes";
import { colorMap } from "../base_components/colors";
import { Typography } from "../base_components/typography";

export const HeroInternal = styled.div`
  max-width: 1440px;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
`;

export const DetailStyle = styled(Typography.SH1)`
  color: ${colorMap.brandPurple};
`;

export const DetailNameStyle = styled(Typography.H1)`
  color: ${colorMap.fontLightBlue};
  margin-bottom: 0;
`;

export const DetailContainer = styled.div`
  text-align: left;
  padding: 0px 0px 0px 80px;
  transition: all 700ms;

  @media (max-width: ${sizes.medium}px) {
    margin-top: 90px;
    text-align: center;
  }

  @media (max-width: ${sizes.large}px) {
    background: rgba(255, 255, 255, 0.9);
    padding: 40px 20px;
    text-align: center;
  }

  @media (max-width: ${sizes.small}px) {
    margin-top: 10px;
  }
`;

export const HeroImg = styled.img`
  width: 80%;
  border-radius: 5px;
  height: intrinsic;
`;

export const HeroContainer = styled.div`
  background-color: ${colorMap.white};
  color: ${colorMap.white};
  overflow: hidden;
  position: relative;
  height: fit-content;
  min-height: 80vh;
  display: flex;
  z-index: 1;
  justify-content: center;

  padding-left: 80px;
  padding-right: 80px;

  @media (max-width: ${sizes.medium}px) {
    height: auto;
    min-height: inherit;
    padding: 60px 20px;
  }

  @media (max-width: ${sizes.large}px) {
    padding: 40px 20px;
  }
`;

export const HeroCol = styled(Col)`
  position: relative;
  padding: 40px 20px;

  @media (max-width: 768px) {
    flex-basis: 100%;
  }

  @media (max-width: ${sizes.small}px) {
    padding: 10px 20px;
  }
`;

export const HeroImgCol = styled(HeroCol)`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: ${sizes.medium}px) {
    margin-top: 50px;
  }

  @media (max-width: ${sizes.small}px) {
    margin-top: 10px;
  }
`;

export const HeroRow = styled(Row)`
  align-items: center;
  margin-top: 60px;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
`;

export const LeftBlob = styled.div`
  position: absolute;
  top: -10%;
  left: -20%;
  width: 500px;
  transition: all 700ms;

  @media (min-width: ${sizes.xl}px) {
    width: 800px;
  }

  @media (max-width: ${sizes.medium}px) {
    width: 400px;
  }

  @media (max-width: ${sizes.small}px) {
    width: 300px;
  }
`;

export const RightBlob = styled.div`
  position: absolute;
  bottom: -10%;
  right: -20%;
  width: 500px;
  transition: all 700ms;

  @media (min-width: ${sizes.xl}px) {
    width: 800px;
  }

  @media (max-width: ${sizes.medium}px) {
    width: 400px;
  }

  @media (max-width: ${sizes.small}px) {
    width: 300px;
  }
`;

export const TopFernContainer = styled.div`
  position: absolute;
  top: -20%;
  right: -10%;
  width: 800px;
  transition: all 700ms;
  transform: rotate(180deg);

  @media (max-width: ${sizes.medium}px) {
    width: 500px;
    top: -5%;
    right: -15%;
  }

  @media (max-width: ${sizes.small}px) {
    width: 350px;
    top: 0;
    right: 0;
  }
`;

export const BottomFernContainer = styled.div`
  position: absolute;
  bottom: -10%;
  left: -10%;
  z-index: -1;
  width: 800px;
  transition: all 700ms;
  fill: #a4c7df;

  @media (max-width: 980px) {
    bottom: -30%;
  }

  @media (max-width: ${sizes.medium}px) {
    width: 500px;
    left: -10%;
    opacity: 0;
  }

  @media (max-width: ${sizes.small}px) {
    width: 350px;
    top: -15%;
    left: -10%;
    opacity: 0;
  }
`;
