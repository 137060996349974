import styled from "styled-components";
import React from "react";
import { colorMap } from "./colors";
import { sizes } from "./screenSizes";

export const PrimaryButton = styled.a`
  border: 2px solid ${props => colorMap[props.buttonColor]};
  background: ${props => colorMap[props.buttonColor]};
  color: ${props => colorMap[props.textColor]};
  width: auto;
  height: 50px;
  box-shadow: 10px 10px 0 0 ${props => colorMap[props.shadowColor]};
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 5px;
  cursor: pointer;
  padding: 15px;
  text-decoration: none;
  display: flex;
  align-items: center;

  :hover,
  :focus {
    box-shadow: 0 0 0 0 white;
  }

  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: ${sizes.medium}px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: ${sizes.small}px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
